export const seo = {
  url: 'client/fuel-surcharge',
  title: {
    pl: 'Korekta Paliwowa',
    en: 'Fuel Surcharge',
  },
  desc: {
    pl: `Korekta paliwowa pozwala oprzeć współpracę o stałe stawki i zachować stabilność serwisu oraz organizować transporty w możliwie najszybszym czasie.`,
    en: `The fuel surcharge allows to build cooperation on fixed rates and to maintain stability of service as well as to organise transports as soon as possible.`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'korekta paliwowa',
    'paliwo',
    'diesel',
    'beznyna',
    'fuel surcharge'
  ],
}

export const intro = {
  title: {
    pl: 'Korekta Paliwowa',
    en: 'Fuel Surcharge',
  },
  desc: {
    pl: `Korekta paliwowa pozwala oprzeć współpracę o stałe stawki i zachować stabilność serwisu oraz organizować transporty w możliwie najszybszym czasie.`,
    en: `The fuel surcharge allows to build cooperation on fixed rates and to maintain stability of service as well as to organise transports as soon as possible.`,
  },
}

export const content = {
  title: {
    pl: 'Aktualna Korekta Paliwowa',
    en: 'Fuel Surcharge',
  },
  desc: {
    pl: `Informujemy, że dodatek paliwowy:`,
    en: `Please be informed that the fuel allowance:`,
  },
}

export const button1 = {
  label: {
    pl: 'Ceny paliw PKN Orlen',
    en: 'PKN ORLEN fuel prices',
  },
  link: {
    pl: 'https://www.orlen.pl/pl/dla-biznesu/hurtowe-ceny-paliw?Fuel=ONEkodiesel&Year=2022',
    en: 'https://www.orlen.pl/en/for-business/fuel-wholesale-prices',
  },
}

export const button2 = {
  label: {
    pl: 'Korekta Paliwowa PDF',
    en: 'Fuel Surcharge PDF',
  },
  link: {
    pl: '/pdf/korekta_paliwowa.pdf',
    en: '/pdf/fuel_surcharge.pdf',
  },
}
